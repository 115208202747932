import * as React from 'react';
import { Routes, Route } from "react-router-dom";
import Box from '@mui/material/Box';
import Regions from './view/explore/Regions';
import Areas from './view/explore/Area';
import Sectors from './view/explore/Sector';
import Crags from './view/explore/Crag';
import CragRoutes from './view/explore/Route';
import SearchPage from './view/search/Search';
import Gyms from './view/gym/Gyms';
import Gym from './view/gym/Gym';
import Home from './view/home/Home';
import Login from './navigation/profile/Login'
import Register from './navigation/profile/Register';
import ResetPassword from './navigation/profile/ResetPassword.js';
import Map from './view/map/Map';
import Ascent from './view/ascents/Ascents';
import Activity from './view/activity/Activity';
import Logbook from './view/logbook/Logbook';
import Message from './view/message/Conversation';

export default function AppRoutes(props) {
  return (
    <Box sx={{ padding: 0, height: '100%' }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/reset_password" element={<ResetPassword />} />
        <Route path="/region" element={<Regions />} />
        <Route path="area" element={<Areas />}>
          <Route path=":regionId" element={<Areas />}/>
        </Route>
        <Route path="sector" element={<Sectors />}>
          <Route path=":areaId" element={<Sectors />}/>
        </Route>
        <Route path="crag" element={<Crags />}>
          <Route path=":sectorId" element={<Crags />}/>
        </Route>
        <Route path="route" element={<CragRoutes />}>
          <Route path=":cragId" element={<CragRoutes />}/>
        </Route>
        <Route path="/search" element={<SearchPage />} />
        <Route path="gyms" element={<Gyms />} />
        <Route path="gym" element={<Gym />} >
          <Route path=":gymId" element={<Gym />} />
        </Route>
        <Route path="/map" element={<Map />} >
          {/* <Route path=":routeId" element={<Map />} /> */}
        </Route>
        <Route path="ascents" element={<Ascent />} />
        <Route path="activity" element={<Activity />} />
        <Route path="logbook" element={<Logbook />} />
        <Route path="message" element={<Message />} />
      </Routes>
    </Box>
  );
}
