import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import StringAvatar from '../../components/avatar/StringAvatar';
import Paper from '@mui/material/Paper';
import AddComment from './AddComment';

export default function ActivityComment (props) {
  const { data, gymActivity, color } = props;
  const [comments, setComments] = useState([]);

  useEffect(() => {
    setComments(data?.map((c) => c));
  }, []);

  const updateComments = (comment) => {
    const newComments = comments.filter((c) => c).map((c) => ({ ...c }));
    newComments.push({...comment, id: newComments.length + 1 });
    setComments(newComments);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%', maxWidth: 1024, bgcolor: color }}>
      {comments?.filter((d) => d).map((row) => (
        <Paper key={row.id} elevation={4} sx={{ mt: '10px', display: 'flex', justifyContent: 'center', bgcolor: color, width: '100%' }}>
          <Box
            sx={{ '&:last-child td, &:last-child th': { border: 0 }, width: '100%' }}
          >
            <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
              <Box style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                <StringAvatar userName={row.user_name} sx={{ m: 5 }}/>
                <Typography sx={{ ml: 1 }}>{row.user_name}</Typography>
              </Box>
              <Typography sx={{ p: 1 }}>{dayjs(row.created_at).format('YYYY-MM-DD HH:mm')}</Typography>
            </Box>
            <Box>{row.content}</Box>
          </Box>
        </Paper>
      ))}
      <AddComment gymActivity={gymActivity} updateComments={updateComments} color={color}/>
    </Box>
  );
}