import * as React from 'react';
import { useNavigate } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useSessionContext } from '../../context/sessionContext';
import { menuItems } from './menuItems';
import './sideMenu.css'

export default function SideMenu(props) {
  const { sideAnchorEl, isSideMenuOpen, handleMenuClose, profileMenuId } = props;
  const { session } = useSessionContext();
  const isUserLogged = Boolean(session);

  const navigate = useNavigate();
  const handleClick = (route) => {
    navigate(route);
    handleMenuClose();
  }

  return (
    <Menu
      anchorEl={sideAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isSideMenuOpen}
      onClose={handleMenuClose}
    >
      {menuItems.map((item, idx) => (
        <div className='side_menu_element' key={idx}>
          <img className='side_menu_icon' src={`/icons/${item.icon}.svg`} alt={item.icon}/>
          <MenuItem onClick={() => handleClick(item.route)}>{item.name}</MenuItem>
        </div>
      ))}
    </Menu>
  );
}
