import axios from 'axios';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import NotificationsIcon from '@mui/icons-material/Notifications';
import CircularProgress from '@mui/material/CircularProgress';
import { useSessionContext } from '../../context/sessionContext';
import { getFirebaseToken } from '../../components/utils/firebase';

export default function ProfileMenu(props) {
  const { profileAnchorEl, isProfileMenuOpen, handleMenuClose, profileMenuId } = props;
  const { session, clear_session } = useSessionContext();
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isUserLogged = Boolean(session);
  const [isTokenFound, setTokenFound] = useState(false);

  const fetchData = async (data) => {
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/user/logout`,
        {
          firebaseToken: isTokenFound
        },
        {
          headers: {
            Authorization: `B ${session.token}`
          }
        }
      );
      setIsLoading(false);
      clear_session();
    } catch (error) {
      setIsLoading(false);
      setIsError(error.response.data.error || true);
      console.error(error);
    }
  }

  const handleLogout = () => {
    fetchData();
    handleMenuClose();
  }

  useEffect(() => {
    if(session) {
      getFirebaseToken({ setTokenFound, userToken: session.token });
    }

  }, [session]);

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Menu
      anchorEl={profileAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isProfileMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>{isTokenFound ? (<NotificationsIcon color='primary'/>) : (<NotificationsOffIcon />)}</MenuItem>
      <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
      <MenuItem onClick={handleMenuClose}>My account</MenuItem>
      {isUserLogged && <Link to='/logbook' style={{ textDecoration: 'none' }}><MenuItem onClick={handleMenuClose}>Logbook</MenuItem></Link>}
      {!isUserLogged ? <Link to='/login' style={{ textDecoration: 'none' }}><MenuItem onClick={handleMenuClose}>Login</MenuItem></Link> :
        <MenuItem onClick={handleLogout}>Logout</MenuItem> }
    </Menu>
  );
}
