import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, isSupported } from "firebase/messaging";
import axios from "axios";

const { REACT_APP_VAPID_KEY } = process.env
const publicKey = REACT_APP_VAPID_KEY;

const firebaseConfig = {
  apiKey: "AIzaSyDHiBJR4gzZMctiYcD1vb9sfu9yU6XLOG0",
  authDomain: "wspinder-5725c.firebaseapp.com",
  projectId: "wspinder-5725c",
  storageBucket: "wspinder-5725c.appspot.com",
  messagingSenderId: "897975338519",
  appId: "1:897975338519:web:86932c95d77eec3aa7be52",
  measurementId: "G-4J2CS4CXSH"
};

// Initialize Firebase
initializeApp(firebaseConfig);
let messaging;
isSupported().then((r) =>{
  if(r) {
    messaging = getMessaging();
  }
});

export const supported = () => {
  return isSupported();
}


const sendFirebaseToken = async (firebaseToken, userToken) => {
  try {
    await axios.post(
      `${process.env.REACT_APP_API_URL}/user/firebaseToken`,
      { firebaseToken: firebaseToken },
      {
        headers: {
          Authorization: `B ${userToken}`
        }
      }
    );
  } catch (error) {
    console.error(error);
  }
}

export const getFirebaseToken = async ({ setTokenFound, userToken }) => {
  if(!await isSupported()) return;
  return getToken(messaging, { vapidKey: publicKey }).then((currentToken) => {
    if (currentToken) {
      sendFirebaseToken(currentToken, userToken);
      setTokenFound(currentToken);
    } else {
      setTokenFound(false);
    }
  }).catch((err) => {
    console.error(err);
  });
}

export function onMessageListener(cb) {
  onMessage(messaging, (payload) => {
    cb(payload)
  });
}
