import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import AddAscent from './AddAscent';
import style from './modalStyle';

export default function AddAscentModal(props) {
  const { open, handleClose, addNewActivity, route_id } = props;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AddAscent  handleClose={handleClose} addNewActivity={addNewActivity} route_id={route_id}/>
        </Box>
      </Modal>
    </div>
  );
}
