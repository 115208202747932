import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Icon } from '@mui/material';
import { getOrderQuery, getFiltersQuery } from '../../components/utils/query/queryHelper';
import { useFiltersListContext } from '../../context/filtersContext';
import Grade from '../../icons/grade.svg';
import dayjs from 'dayjs';
import { useSessionContext } from '../../context/sessionContext';
// import './ascent.css';

export default function Logbook() {

  const { session, clear_session } = useSessionContext();
  const [isLoading, setIsLoading] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [isError, setIsError] = useState(false);
  const [order, setOrder] = useState([]);
  const { pathname } = useLocation();
  const context = pathname.split('/')[1];

  const { set_filters, filtersValues, clear_filters } = useFiltersListContext();

  const params = useParams();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ascent/my?where=${getFiltersQuery(filtersValues, context)}${getOrderQuery(order)}`,
        {
          headers: {
            Authorization: `B ${session.token}`
          }
        }
      );
      setIsLoading(false);
      setRoutes(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  }

  const fetchFilters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ascent/filters`
      );
      set_filters(response.data);
    } catch(err) {
      console.error(err);
    }
  }


  useEffect(() => {
    fetchData();
  }, [order]);

  useEffect(() => {
    if(filtersValues && context === filtersValues.context) {
      fetchData();
    }
  }, [filtersValues]);

  useEffect(() => {
    fetchFilters();
    return () => clear_filters();
  }, []);

  const handleClick = (id) => {}

  const card = (route) => <div className='route' onClick={() => handleClick(route.id)} key={`ascent-${route.id}`}>
    <div className='route_info'>
      <div className='ascent_infoBox'>
        <div className='route_title'>{route.route}</div>
      </div>
      <div className='route_infoBox'>
        <Icon className='route_pointer'><img src={Grade} alt=""></img></Icon>
        <div className='box_text'>{route.grade}</div>
      </div>
      <div className='route_infoBox'>
        <div className='route_title'>{route.style.toUpperCase()}</div>
      </div>
    </div>
    <div className='route_info'>
      <div className='route_infoBox'>
        <div className='box_text'>{route.content}</div>
      </div>
    </div>
    <div className='route_info'>
      <div className='ascent_infoBox'>
        <div className='route_title'>{route.user_name}</div>
      </div>
      <div className='ascent_infoBox'>
        <div className='route_title' >Data: </div>
        <div className='route_title'>{dayjs(route.date).format('YYYY-MM-DD')}</div>
      </div>
    </div>
  </div>;

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }
  return (
    <div className='route_container'>
      <div className='title_container'>
        <div className='title' >Moje przejścia</div>
      </div>
      {routes.map((route) => card(route))}
    </div>
  );
}

