import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from "react-hook-form";
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Collapse from '@mui/material/Collapse';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useSessionContext } from '../../context/sessionContext';

export default function AddComment(props) {
  const { gymActivity, updateComments, color } = props;
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { session } = useSessionContext();
  const [isError, setIsError] = useState(false);
  const [open, setOpen] = useState(false);

  const fetchData = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/activity/gym/comment`,
        { ...data,
          gym_activity: gymActivity,
        },
        {
          headers: {
            Authorization: `B ${session.token}`
          }
        }
      );
      setIsLoading(false);
      updateComments({ ...response.data, user_name: session.user_name });
    } catch (error) {
      setIsLoading(false);
      setIsError(error.response.data.error || true);
      console.error(error);
    }
  }

  const onSubmit = async (data) => {
    await fetchData(data);
    reset();
    handleOpen();
  }

  const handleOpen = () => {
    setOpen(!open)
  }

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  const addForm = () => {
    if(!session) {
      return (
        <Box sx={{ display: 'flex' }}>
          <Typography id="modal-modal-title" variant="h6" component="h4">
            Aby dodać aktywnośc musisz się zalogować
          </Typography>
        </Box>
      )
    }

    return (
      <Box sx={{ bgcolor: color }}>
        <form onSubmit={handleSubmit(onSubmit)} style={{ flexDirection: 'column' }}>
          {/* register your input into the hook by invoking the "register" function */}
          <TextField id="content" label="Kometarz" variant="standard" {...register("content", { required: true })} fullWidth/>
          {errors.email && <span style={{ color: 'red' }}>Email is required</span>}

          <input type="submit" value='Dodaj komentarz'/>
          {isError && <Alert severity="error">{isError}</Alert>}
        </form>
      </Box>
    )
  }

  return (
    <Box>
      <ListItemButton onClick={handleOpen}>
        <ListItemText sx={{ textAlign: 'end' }} primary='Odpowiedz' />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit style={{ width: '100%' }}>
        {addForm()}
      </Collapse>
    </Box>
  );
}
