import axios from 'axios';
import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import IconButton from '@mui/material/IconButton';
import Alert from '@mui/material/Alert';
import CircularProgress from '@mui/material/CircularProgress';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { getOrderQuery } from '../utils/query/queryHelper';

const dependenciesArray = ['region', 'area', 'sector', 'crag'];

export default function CustomDeepNativeSelect(props) {
  const { name, type, handleFilterValueChange, filterValues, prefix, handleCleanOneFilter } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [selected, setSelected] = useState('');

  const handleClear = (name) => {
    setSelected('');
    handleCleanOneFilter(name);
  }

  const fetchData = async (type, value) => {
    setIsLoading(true);
    const link = prefix ? `/${prefix}/${type}`: `/${type}`
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${link}${`?fields=id,name`}${value ? `&where=${value.name};${value.value}` : ''}${`${getOrderQuery([{ column: 'name', direction: false }])}`}`
      );
      setIsLoading(false);
      setData(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  }

  useEffect(() => {
    const indexOfEl = dependenciesArray.indexOf(type);
    const filterBefore = filterValues.find(f => f.name === dependenciesArray[indexOfEl - 1]);
    if(indexOfEl === 0 || (filterBefore && filterBefore.value) || indexOfEl < 0) {
      fetchData(type, filterBefore);
    }
  }, [filterValues]);

  useEffect(() => {
    if(filterValues && !filterValues.find(f => f.name === type)) {
      setSelected('');
    }
  }, [filterValues]);

  const handleSelect = (e) => {
    setSelected(e.target.value);
    handleFilterValueChange(e);
  }

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }

  return (
    <Box sx={{ minWidth: 220 }}>
      <FormControl style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
        <InputLabel style={{ width: '80%'}} variant="standard" htmlFor={`uncontrolled-native-${name}`}>
          {name}
        </InputLabel>
        <Select
          style={{ width: '90%'}}
          value={selected}
          label={name}
          inputProps={{
            name,
            id: `uncontrolled-native-${name}`,
          }}
          onChange={handleSelect}
        >
          {data.map((o, i) => (
            <MenuItem value={o.id || o} key={i}>{o.name || o}</MenuItem>
          ))}
        </Select>
        <Box style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton
            disabled={!selected}
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => handleClear(name)}
          >
            <DeleteForeverIcon />
          </IconButton>
        </Box>
      </FormControl>
      {isError && <Alert severity="error">{isError}</Alert>}
    </Box>
  );
}
