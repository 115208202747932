import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getOrderQuery } from '../../components/utils/query/queryHelper';
import { Icon } from '@mui/material';
import Pointer from '../../icons/pointer.svg';

export default function Areas() {

  const [isLoading, setIsLoading] = useState(false);
  const [areas, setAreas] = useState([]);
  const [isError, setIsError] = useState(false);
  const [order, setOrder] = useState([]);

  const params = useParams();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/area?where=region;${params.regionId}&limit=100${getOrderQuery(order)}`
      );
      setIsLoading(false);
      setAreas(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  }

  const navigate = useNavigate();
  const handleClick = (route) => {
    navigate(`/sector/${route}`);
  }

  const handleMap = (areaId) => {
    navigate({ pathname: '/map', search: createSearchParams({ location: 'area/locations', id: areaId }).toString() });
  }

  const areaCard = (row) => <div className='region' key={row.id}>
    <div className='title' onClick={() => handleClick(row.id)}>{row.name}</div>
    <div className='info'>
      <div>Drogi: {row.routes}</div>
      <Icon className='pointer'><img src={Pointer} alt="" onClick={() => handleMap(row.id)}></img></Icon>
    </div>
  </div>;

  useEffect(() => {
    fetchData();
  }, [order]);

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }
  return (
    <div className='regionContainer'>
        <div className='view_title' >Rejon</div>
      {areas.map((row) => areaCard(row))}
    </div>
  );
}

