import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert'
import Activity from './Activity';
import { getOrderQuery, getFiltersQuery } from '../../components/utils/query/queryHelper';
import { useFiltersListContext } from '../../context/filtersContext';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import Modal from '../../components/modal/Modal';
import { useSessionContext } from '../../context/sessionContext';

export default function Gym() {

  const [isLoading, setIsLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [isError, setIsError] = useState(false);
  const [order, setOrder] = useState([]);
  const [open, setOpen] = useState(false);
  const { session } = useSessionContext();
  const { pathname } = useLocation();
  const context = pathname.split('/')[1];

  const { set_filters, filtersValues, clear_filters } = useFiltersListContext();

  const handleOpenModal = () => setOpen(true);
  const handleCloseModal = () => setOpen(false);

  const params = useParams();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity/gym/${params.gymId}?${`where=${getFiltersQuery(filtersValues, context).substring(1)}`}${getOrderQuery(order)}`
      );
      setIsLoading(false);
      setActivities(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
    }
  }

  const fetchFilters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/gym/activity/filters`
      );
      set_filters(response.data);
    } catch(err) {
      console.error(err);
    }
  }

  const addNewActivity = (newActivity) => {
    const oldActivities = activities.map((a) => ({ ...a }));
    oldActivities.push({ ...newActivity, user_name: session.user_name });
    setActivities(oldActivities);
  }


  useEffect(() => {
    fetchData();
  }, [order, filtersValues]);

  // useEffect(() => {
  //   fetchFilters();

  //   return () => clear_filters();
  // }, []);

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {activities.map((row, i) => (
              <Activity key={row.id} row={row} index={i}/>
            ))}
      <Fab color="primary" aria-label="add" style={{ position: 'fixed', bottom: '50px', right: '50px' }}>
        <AddIcon onClick={handleOpenModal} />
      </Fab>
      <Modal open={open} handleClose={handleCloseModal} addNewActivity={addNewActivity} />
      {isError && <Alert severity="error">{isError}</Alert>}
    </Box>
  );
}

