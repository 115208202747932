import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import { useSessionContext } from '../../context/sessionContext';
import dayjs from 'dayjs';

export default function WritePrivateMessage(props) {
  const { handleClose, userId } = props;
  const { register, handleSubmit, formState: { errors }, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { session } = useSessionContext();
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const fetchData = async (data) => {
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/message`,
        data,
        {
          headers: {
            Authorization: `B ${session.token}`
          }
        }
      );
      setIsLoading(false);
      // addNewActivity(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(error.response.data.error || true);
      console.error(error);
    }
  }

  const prepareData = (data) => {
    return {
      content: data.content,
      date: dayjs(data.hour_from).format('YYYY-MM-DD'),
      style: data.style,
      receiverId: userId
    }
  }

  const onSubmit = async data => {
    const transformedData = prepareData(data);
    await fetchData(transformedData);
    reset();
    handleClose();
  };

  const handleGoToLogin = () => {
    handleClose();
    return navigate("/login");
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column'
  };

  if(!session) {
    return (
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Aby napisać wiadomość musisz się zalogować
        </Typography>
        <Button onClick={handleGoToLogin}>Przejdź do logowania</Button>
        <Button onClick={handleClose}>Zamknij</Button>
      </Box>
    )
  }

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} style={{ flexDirection: 'column' }}>
        <TextField id="content" label="Kometarz" variant="standard" {...register("content", { required: true })} fullWidth/>
        {errors.content && <span style={{ color: 'red' }}>Content is required</span>}
        <input type="submit" value='Wyślij widomość' />
        {isError && <Alert severity="error">{isError}</Alert>}
      </form>
    </Box>
  )
}