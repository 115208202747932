import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Icon } from '@mui/material';
import { getOrderQuery } from '../../components/utils/query/queryHelper';
import './region.css';
import Pointer from '../../icons/pointer.svg';

export default function Regions() {

  const [isLoading, setIsLoading] = useState(false);
  const [regions, setRegions] = useState([]);
  const [isError, setIsError] = useState(false);
  const [order, setOrder] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/region${order.length ? `?${getOrderQuery(order)}` : ''}`
      );
      setIsLoading(false);
      setRegions(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  }

  const navigate = useNavigate();
  const handleClick = (route) => {
    navigate(`/area/${route}`);
  }

  useEffect(() => {
    fetchData();
  }, [order]);

  const handleMap = (regionId) => {
    navigate({ pathname: '/map', search: createSearchParams({ location: 'region', id: regionId }).toString() });
  }

  const regionCard = (region) => <div className='region' key={`region-${region.id}`}>
    <div className='title' onClick={() => handleClick(region.id)}>{region.name}</div>
    <div className='info'>
      <div>Drogi: {region.routes}</div>
      <Icon className='pointer'><img src={Pointer} alt="" onClick={() => handleMap(region.id)}></img></Icon>
    </div>
  </div>;

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }

  return (
    <div className='regionContainer'>
        <div className='view_title' >Region</div>
      {regions.map((region) => regionCard(region))}
    </div>
  );
}

