import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from "react-hook-form";
import './Login.css';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

export default function Register() {
  const { register, handleSubmit, formState: { errors }, getValues } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [response, setResponse] = useState(null);

  const fetchData = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user`,
        data
      );
      setIsLoading(false);
      setResponse(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(error.response.data.error || true);
      console.error(error);
    }
  }

  const onSubmit = data => {
    fetchData(data);
  };

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
        <CircularProgress />
      </Box>
    )
  }

  if(response) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
        <Typography variant='h4'>Wejdź na pocztę aby dokończyć rejstrację</Typography>
      </Box>
    )
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} style={{ flexDirection: 'column' }}>
        {/* register your input into the hook by invoking the "register" function */}
        <TextField id="email" label="Email" variant="standard" {...register("email", { required: true })} fullWidth/>
        {errors.email && <span style={{ color: 'red' }}>Email is required</span>}
        <TextField id="name" label="Imię" variant="standard" {...register("name", { required: true })} fullWidth/>
        {errors.name && <span style={{ color: 'red' }}>Imię jest wymagane</span>}
        {/* include validation with required or other standard HTML validation rules */}
        <TextField type='password' id="password" label="Password" variant="standard" {...register("password", { required: true })} fullWidth/>
        {/* errors will return when field validation fails  */}
        {errors.password && <span style={{ color: 'red' }}>Password is required</span>}
        <TextField type='password' id="password_confirm" label="Powtórz hasło" variant="standard" {...register("password_confirm", {
          required: true,
          validate: value => value === getValues('password'),
          })} fullWidth/>
        {/* errors will return when field validation fails  */}
        {errors.password_confirm && <span style={{ color: 'red' }}>Hasła muszą być takie same</span>}

        <input type="submit" />
        {isError && <Alert severity="error">{isError}</Alert>}
      </form>
    </Box>
  );
}
