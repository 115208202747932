import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Icon } from '@mui/material';
import { getOrderQuery, getFiltersQuery } from '../../components/utils/query/queryHelper';
import { useFiltersListContext } from '../../context/filtersContext';
import Grade from '../../icons/grade.svg';
import Length from '../../icons/length.svg';
import Chain from '../../icons/chain.svg';
import Drill from '../../icons/drill-man.svg';
import './route.css';
// import { searchForPartner } from '../../components/utils/searchForPartner';
import SearchForPartnerModal from '../../components/modal/SearchForPartnerModal';
import AddAscentModal from '../../components/modal/AddAscentModal';

export default function Sectors() {

  const [isLoading, setIsLoading] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [isError, setIsError] = useState(false);
  const [order, setOrder] = useState([]);
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [add, setAdd] = useState(false);
  const [cragId, setCragId] = useState(null);
  const [routeId, setRouteId] = useState(null);

  const context = pathname.split('/')[1];

  const { set_filters, filtersValues, clear_filters } = useFiltersListContext();

  const params = useParams();

  const handleOpenModal = (crag_id) => {
    setCragId(crag_id)
    setOpen(true);
  };
  const handleCloseModal = () => setOpen(false);

  const handleOpenAddModal = (route_id) => {
    setRouteId(route_id)
    setAdd(true);
  };
  const handleCloseAddModal = () => setAdd(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/route?where=crag;${params.cragId}${getFiltersQuery(filtersValues, context)}${getOrderQuery(order)}`
      );
      setIsLoading(false);
      setRoutes(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  }

  const fetchFilters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/route/filters`
      );
      set_filters(response.data);
    } catch(err) {
      console.error(err);
    }
  }


  useEffect(() => {
    fetchData();
  }, [order]);

  useEffect(() => {
    if(filtersValues && context === filtersValues.context) {
      fetchData();
    }
  }, [filtersValues]);

  useEffect(() => {
    fetchFilters();
    return () => clear_filters();
  }, []);

  const handleClick = (id) => {
    console.log('Route id: ', id);
  }

  const card = (route) => <div className='route' onClick={() => handleClick(route.id)}>
    <div className='route_info'>
      <div className='route_title' >{route.name}</div>
      <div className='route_infoBox'>
        <Icon className='route_pointer'><img src={Grade} alt=""></img></Icon>
        <div className='box_text'>{route.grade}</div>
      </div>
    </div>
    <div className='route_info'>
      <div className='route_infoBox'>
        <Icon className='route_pointer'><img src={Drill} alt=""></img></Icon>
        <div className='box_text'>{route.author || 'nieznany'}</div>
      </div>
    </div>
    <div className='route_info'>
      <div className='route_infoBox'>
        <Icon className='route_pointer'><img src={Length} alt=""></img></Icon>
        <div className='box_text'>{route.length} m</div>
      </div>
      <div className='route_infoBox'>
        <Icon className='route_pointer'><img style={{ margin: '1px' }} src={Chain} alt=""></img></Icon>
        <div className='box_text'>{route.protection || 'brak'}</div>
      </div>
    </div>
    <div className='search_info'>
    <div className='search_infoBox'>
        <button className='search_button' onClick={() => handleOpenAddModal(route.id)}>Dodaj przejście</button>
      </div>
      <div className='search_infoBox'>
        <button className='search_button' onClick={() => handleOpenModal(route.crag_id)}>Szukaj partnera</button>
      </div>
    </div>
  </div>;

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }
  return (
    <div className='route_container'>
      <div className='view_title' >Droga</div>
      {routes.map((route) => card(route))}
      <SearchForPartnerModal open={open} handleClose={handleCloseModal} crag_id={cragId}/>
      <AddAscentModal open={add} handleClose={handleCloseAddModal} route_id={routeId}/>
    </div>
  );
}

