import { useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Collapse from '@mui/material/Collapse';
import { grey, yellow } from '@mui/material/colors';
import ActivityComment from './ActivityComment';
import ActivityCard from './ActivityCard';

export default function Activity(props) {
  const { row, index } = props;
  const [open, setOpen] = useState(false);

  const handleCommentsOpen = () => {
    setOpen(!open)
  }

  const getColor = (index) => {
    if(index%2) {
      return grey[200];
    }
    return yellow[200];
  }

  return (
    <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', pt: 1 }}>
      <Divider/>
      <ActivityCard row={row} open={open} handleCommentsOpen={handleCommentsOpen} color={getColor(index)}/>
      <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: '95vw', display: 'flex', justifyContent: 'center', maxWidth: 1024 }}>
        <ActivityComment gymActivity={row.id} data={row.comments} color={getColor(index)}/>
      </Collapse>
      <Divider/>
    </Box>
    )
}
