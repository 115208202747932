import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Icon } from '@mui/material';
import { getOrderQuery, getFiltersQuery } from '../../components/utils/query/queryHelper';
import { useFiltersListContext } from '../../context/filtersContext';
import Grade from '../../icons/grade.svg';
import Length from '../../icons/length.svg';
import Protection from '../../icons/protection.svg';
import Rock from '../../icons/rock.svg';
import Drill from '../../icons/drill-man.svg';
import SearchForPartnerModal from '../../components/modal/SearchForPartnerModal';
import AddAscentModal from '../../components/modal/AddAscentModal';
import './search.css';

export default function SearchPage() {

  const [isLoading, setIsLoading] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [isError, setIsError] = useState(false);
  const [order, setOrder] = useState([]);
  const [open, setOpen] = useState(false);
  const [add, setAdd] = useState(false);
  const [cragId, setCragId] = useState(null);
  const [routeId, setRouteId] = useState(null);
  const { set_filters, filtersValues, clear_filters } = useFiltersListContext();
  const { pathname } = useLocation();
  const context = pathname.split('/')[1];

  const handleOpenModal = (crag_id) => {
    setCragId(crag_id)
    setOpen(true);
  };
  const handleCloseModal = () => setOpen(false);

  const handleOpenAddModal = (route_id) => {
    setRouteId(route_id)
    setAdd(true);
  };
  const handleCloseAddModal = () => setAdd(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/search?${`${getFiltersQuery(filtersValues, context)}`}${getOrderQuery(order)}`
      );
      setIsLoading(false);
      setRoutes(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  }

  const fetchFilters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/search/filters`
      );
      set_filters(response.data);
    } catch(err) {
      console.error(err);
    }
  }


  useEffect(() => {
    fetchData();
  }, [order]);

  useEffect(() => {
    if(filtersValues && context === filtersValues.context) {
      fetchData();
    }
  }, [filtersValues]);

  useEffect(() => {
    fetchFilters();

    return () => clear_filters();
  }, []);

  const handleClick = (id) => {}

  const columns = [
    // 'route',
    // 'grade',
    'author',
    // 'year',
    'crag',
    'sector',
    'area',
    'region',
  ];

  const card = (route) => <div className='search' onClick={() => handleClick(route.id)}>
    <div className='search_info'>
      <div className='search_title' >{route.route}</div>
      <div className='search_infoBox'>
        <Icon className='search_pointer'><img src={Grade} alt=""></img></Icon>
        <div className='search_box_text'>{route.grade}</div>
        {/* <div>{route.author}</div> */}
      </div>
    </div>
    <div className='search_info'>
      <div className='search_infoBox'>
        <Icon className='search_pointer'><img src={Drill} alt=""></img></Icon>
        <div className='search_box_text'>{route.author || 'nieznany'}</div>
      </div>
    </div>
    <div className='search_info'>
      <div className='search_infoBox'>
        <Icon className='search_pointer'><img src={Rock} alt=""></img></Icon>
        {/* <div className='box_text'>{route.area}/{route.sector}/{route.crag}</div> */}
        <div className='search_box_text'>{route.crag}</div>
      </div>
      <div className='search_infoBox'>
        <Icon className='search_pointer'><img src={Length} alt=""></img></Icon>
        <div className='search_box_text'>{route.length} m</div>
      </div>
      <div className='search_infoBox'>
        <Icon className='search_pointer'><img src={Protection} alt=""></img></Icon>
        <div className='search_box_text'>{route.protection || 'brak'}</div>
      </div>
    </div>
    <div className='search_info'>
      <div className='search_infoBox'>
        <button className='search_button' onClick={() => handleOpenAddModal(route.route_id)}>Dodaj przejście</button>
      </div>
      <div className='search_infoBox'>
        <button className='search_button' onClick={() => handleOpenModal(route.crag_id)}>Szukaj partnera</button>
      </div>
    </div>
  </div>;

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }
  return (
    <div className='search_container'>
      <div className='view_title' >Szukaj</div>
      {routes.map((route) => card(route))}
      <SearchForPartnerModal open={open} handleClose={handleCloseModal} crag_id={cragId}/>
      <AddAscentModal open={add} handleClose={handleCloseAddModal} route_id={routeId}/>
    </div>
  );
}

