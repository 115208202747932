import { useSessionContext } from '../../context/sessionContext';
import WriteMEssage from './WriteMessage';
import StringAvatar from '../../components/avatar/StringAvatar';
import './message.css';

export default function Messages(props) {
  const { messages, expanded, handleExpandClick, conversationId, partnerId } = props;

  const { session } = useSessionContext();

  const handleClick = (id) => {}

  const itsUsersMessage = (user_id) => user_id === session.id;

  const itsExpanded = expanded[conversationId] === 2;

  const messageCard = (message) => <div className='message_container' onClick={() => handleClick(message.id)} key={`ascent-${message.id}`}>
    {!itsUsersMessage(message.user_id) && <StringAvatar userName={message.user_name} small={true} />}
    <div className={itsUsersMessage(message.user_id) ? 'message' : 'message_left'}>
      <div className='message_infoBox'>
        <div className='box_text'>{message.content}</div>
      </div>
    </div>
  </div>;

  const singleMessageCard = (message) => <div className='message_info' onClick={() => handleExpandClick(conversationId)} key={`ascent-${message.id}`}>
    <div className='message_info'>
      {/* <Avatar {...stringAvatar('Kent Dodds')} /> */}
      <div className='message_infoBox'>
        <div className='box_text'>{message.content}</div>
      </div>
    </div>
  </div>;

  return (
    <div className='messages_container'>
      { itsExpanded ? messages.map((message) => messageCard(message)) : singleMessageCard(messages[0])}
      { itsExpanded &&  <WriteMEssage userId={partnerId}/>}
    </div>
  );
}
