import { createContext, useReducer, useContext } from "react";

const initialState = {
  session: null,
};

const actions = {
  SET_SESSION: "SET_SESSION",
  CLEAR_SESSION: "CLEAR_SESSION",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_SESSION:
      localStorage.setItem('session', JSON.stringify(action.session));
      return {
        session: action.session,
      }
    case actions.CLEAR_SESSION: {
      localStorage.removeItem('session');
      return initialState;
    }
    default:
      return state;
  }
};

const SessionContext = createContext();

export const SessionProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    session: state.session,
    set_session: (session) => {
      dispatch({ type: actions.SET_SESSION, session });
    },
    clear_session: () => {
      dispatch({ type: actions.CLEAR_SESSION });
    },
  };

  return (
    <SessionContext.Provider value={value}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSessionContext = () => useContext(SessionContext);
