import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function CustomNativeSelect(props) {
  const { options, defaultValue, name, handleFilterValueChange, filterValues, type, handleCleanOneFilter } = props;
  const [selected, setSelected] = useState('');

  const handleSelect = (e) => {
    setSelected(e.target.value);
    handleFilterValueChange(e);
  }

  const handleClear = (name) => {
    setSelected('');
    handleCleanOneFilter(name);
  }

  useEffect(() => {
    if(filterValues && !filterValues.find(f => f.name === type)) {
      setSelected('');
    }
  }, [filterValues]);

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
        <InputLabel style={{ width: '80%'}} variant="standard" htmlFor={`uncontrolled-native-${name}`}>
          {name}
        </InputLabel>
        <Select
          // defaultValue={defaultValue}
          style={{ width: '90%'}}
          value={selected}
          inputProps={{
            name,
            id: `uncontrolled-native-${name}`,
          }}
          onChange={handleSelect}
        >
          {options.map((o) => (
            <MenuItem value={o} key={o}>{o}</MenuItem>
          ))}
        </Select>
        <Box style={{ width: '10%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            disabled={!selected}
            aria-label="open drawer"
            onClick={() => handleClear(name)}
          >
            <DeleteForeverIcon />
          </IconButton>
        </Box>
      </FormControl>
    </Box>
  );
}
