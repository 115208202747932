import * as React from 'react';
import { useState } from 'react';
import { Link } from "react-router-dom";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import AccountCircle from '@mui/icons-material/AccountCircle';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import ImageIcon from '@mui/icons-material/Image';
// import { getFirebaseToken, onMessageListener } from '../utils/firebase';
import SearchIcon from '@mui/icons-material/Search';
import MenuIcon from '@mui/icons-material/Menu';
import { useFiltersListContext } from '../context/filtersContext';
import './navBar.css'

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function NavBar(props) {
  const { filtersList } = useFiltersListContext();
  const { setProfileAnchorEl, profileMenuId, handleFilterMenuOpen, setSideAnchorEl, sideMenuId } = props;

  const handleProfileMenuOpen = (event) => {
    setProfileAnchorEl(event.currentTarget);
  };

  const handleSideMenuOpen = (event) => {
    setSideAnchorEl(event.currentTarget);
  };

  return (
    <HideOnScroll>
        <div className='nav_bar'>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={sideMenuId}
              aria-haspopup="true"
              onClick={handleSideMenuOpen}
              color="inherit"
            >
              <img className='nav_icon' src={`/icons/hamburger.svg`} alt="hamburger"/>
            </IconButton>
              {filtersList && filtersList.length > 0 && <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="open filter"
                onClick={handleFilterMenuOpen}
            >
              <img className='nav_icon' src={`/icons/filter.svg`} alt="filter"/>
            </IconButton>}
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={profileMenuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <img className='nav_icon' src={`/icons/profile.svg`} alt="profile"/>
            </IconButton>
        </div>
      </HideOnScroll>
  )
}