import { createContext, useReducer, useContext } from "react";

const initialState = {
  filtersList: [],
  filtersValues: {
    context: null,
    values: [],
  },
};

const actions = {
  SET_FILTERS: "SET_FILTERS",
  CLEAR_FILTERS: "CLEAR_FILTERS",
  SET_FILTERS_VALUES: "SET_FILTERS_VALUES",
  CLEAR_FILTERS_VALUES: "CLEAR_FILTERS_VALUES",
};

const reducer = (state, action) => {
  switch (action.type) {
    case actions.SET_FILTERS:
      return {
        filtersList: action.filters.map((f) => ({
          ...f,
        })),
        filterValues: initialState.filtersValues,
      }
    case actions.SET_FILTERS_VALUES:
      return {
        filtersList: state.filtersList.map((f) => ({...f})),
        filtersValues: {
          values: action.filterValues.map((f) => ({
          ...f})),
          context: action.context
        }
      }
    case actions.CLEAR_FILTERS: {
      return initialState;
    }
    case actions.CLEAR_FILTERS_VALUES: {
      return {
        filtersList: state.filtersList.map((f) => ({...f})),
        filterValues: initialState.filtersValues,
      };
    }
    default:
      return state;
  }
};

const FiltersListContext = createContext();

export const FiltersProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    filtersList: state.filtersList,
    filtersValues: state.filtersValues,
    set_filters: (filters) => {
      dispatch({ type: actions.SET_FILTERS, filters });
    },
    clear_filters: () => {
      dispatch({ type: actions.CLEAR_FILTERS });
    },
    set_filters_values: (filterValues, context) => {
      dispatch({ type: actions.SET_FILTERS_VALUES, filterValues, context });
    },
    clear_filters_values: () => {
      dispatch({ type: actions.CLEAR_FILTERS_VALUES });
    },
  };

  return (
    <FiltersListContext.Provider value={value}>
      {children}
    </FiltersListContext.Provider>
  );
};

export const useFiltersListContext = () => useContext(FiltersListContext);
