export const getOrderQuery = (order) => {
  if(!order.length) return '';
  const orderQuery = order.reduce((acc, o) => {
    acc.push(`${o.column};${o.direction ? 'asc' : 'desc'}`);
    return acc;
  }, []);

  return `&order=${orderQuery.join(',')}`;
}

export const getFiltersQuery = (filterValues, context) => {
  if(!filterValues) return '';
  const { values, context: filtersContext } = filterValues;
  if(filtersContext !== context) return '';
  if(!values || !values.length) return '';
  const filtersQuery = values.reduce((acc, f) => {
    acc.push(`where=${f.name};${f.value}`);
    return acc;
  }, []);

  return `&${filtersQuery.join('&')}`;
}