import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getOrderQuery, getFiltersQuery } from '../../components/utils/query/queryHelper';
import { useFiltersListContext } from '../../context/filtersContext';
import './gyms.css'

export default function Gyms() {

  const [isLoading, setIsLoading] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [isError, setIsError] = useState(false);
  const [order, setOrder] = useState([]);
  const { set_filters, filtersValues, clear_filters } = useFiltersListContext();
  const { pathname } = useLocation();
  const context = pathname.split('/')[1];

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/gym?${`where=${getFiltersQuery(filtersValues, context).substring(1)}`}${getOrderQuery(order)}`
      );
      setIsLoading(false);
      setRoutes(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  }

  const fetchFilters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/gym/filters`
      );
      set_filters(response.data);
    } catch(err) {
      console.error(err);
    }
  }


  useEffect(() => {
    fetchData();
  }, [order]);

  useEffect(() => {
    if(filtersValues && context === filtersValues.context) {
      fetchData();
    }
  }, [filtersValues]);

  useEffect(() => {
    fetchFilters();
    return () => clear_filters();
  }, []);


  const navigate = useNavigate();
  const handleClick = (id) => {
    navigate(`/gym/${id}`);
  }

  const card = (route) => <div className='gym_container'>
    <div className='route_info'>
      <div className='route_title' onClick={() => handleClick(route.id)}>{route.name}</div>
      <div className='route_infoBox'>
        <div>{route.type}</div>
      </div>
    </div>
    <div className='route_info'>
      <div className='route_infoBox'>
        <div>{route.voivodeship}/{route.town}</div>
      </div>
    </div>
  </div>;

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }

  return (
    <div className='route_container'>
      <div className='view_title' >Ściany</div>
      {routes.map((route) => card(route))}
    </div>
  );
}

