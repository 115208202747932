import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Icon } from '@mui/material';
import { getOrderQuery, getFiltersQuery } from '../../components/utils/query/queryHelper';
import { useFiltersListContext } from '../../context/filtersContext';
import Grade from '../../icons/grade.svg';
import dayjs from 'dayjs';
import { useSessionContext } from '../../context/sessionContext';
import Message from './Messages';
import './conversation.css';

export default function Conversation() {

  const { session } = useSessionContext();
  const [isLoading, setIsLoading] = useState(false);
  const [conversations, setConversations] = useState([]);
  const [expanded, setExpanded] = useState({});
  const [isError, setIsError] = useState(false);
  const [order, setOrder] = useState([]);
  const { pathname } = useLocation();
  const context = pathname.split('/')[1];

  const { set_filters, filtersValues, clear_filters } = useFiltersListContext();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/message?where=${getFiltersQuery(filtersValues, context)}${getOrderQuery(order)}`,
        {
          headers: {
            Authorization: `B ${session.token}`
          }
        }
      );
      setIsLoading(false);
      setConversations(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  }

  const fetchFilters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/ascent/filters`
      );
      set_filters(response.data);
    } catch(err) {
      console.error(err);
    }
  }

  const getConversationUser = (users) => {
    const conversationUser = users.find(u => u.user_id !== session.id);

    return conversationUser.user_name;
  }

  const handleExpandClick = (conversationId) => {
    const newExpanded = Object.keys(expanded).reduce((acc, key) => {
      acc[key] = 1;
      return acc;
    }, {})
    setExpanded({ ...newExpanded, ...{ [conversationId]: 2 } })
  }


  useEffect(() => {
    fetchData();
  }, [order]);

  useEffect(() => {
    if(filtersValues && context === filtersValues.context) {
      fetchData();
    }
  }, [filtersValues]);

  useEffect(() => {
    fetchFilters();
    return () => clear_filters();
  }, []);

  const handleClick = (id) => {}
  const partnerId = (participants) => participants.find(p => p !== session.id);

  const conversationCard = (conversation) => {
    if(!conversation.last_message || !conversation.messages) return;
    if(!expanded[conversation.id]) {
      setExpanded({ ...expanded, ...{ [conversation.id]: 1 } })
    }
    return <div className='conversation' onClick={() => handleClick(conversation.id)} key={`conversation-${conversation.id}`}>
      <div className='conversation_info'>
        <div className='conversation_title'>{getConversationUser(conversation.users)}</div>
        <div className='conversation_title'>{dayjs(conversation.last_message).format('YYYY-MM-DD HH:MM')}</div>
      </div>
      <Message
        messages={conversation.messages}
        expanded={expanded}
        conversationId={conversation.id}
        handleExpandClick={handleExpandClick}
        partnerId={partnerId(conversation.participants)}/>
    </div>;
  }

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }
  return (
    <div className='conversation_container'>
      <div className='view_title' >Wiadomości</div>
      {conversations.map((conversation) => conversationCard(conversation))}
    </div>
  );
}

