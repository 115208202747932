import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getOrderQuery } from '../../components/utils/query/queryHelper';
import { useFiltersListContext } from '../../context/filtersContext';
import { Icon } from '@mui/material';
import Pointer from '../../icons/pointer.svg';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';

export default function Sectors() {

  const [isLoading, setIsLoading] = useState(false);
  const [sectors, setSectors] = useState([]);
  const [isError, setIsError] = useState(false);
  const [order, setOrder] = useState([]);

  const { set_filters } = useFiltersListContext();

  const params = useParams();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/sector?where=area;${params.areaId}${getOrderQuery(order)}`
      );
      setIsLoading(false);
      setSectors(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  }

  const fetchFilters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/sector/filters`
      );
      set_filters(response.data);
    } catch(err) {
      console.error(err);
    }
  }

  const navigate = useNavigate();
  const handleClick = (route) => {
    navigate(`/crag/${route}`);
  }

  const handleMap = (areaId) => {
    navigate({ pathname: '/map', search: createSearchParams({ location: 'sector/locations', id: areaId }).toString() });
  }

  useEffect(() => {
    fetchData();
  }, [order]);

  useEffect(() => {
    fetchFilters();
  }, []);

  const handleOrderClick = () => {
    const c = 'name';
    const oldOrder = order.map((o) => ({...o}));
    const old = oldOrder.find(s => s.column === c);
    if(!old) {
      setOrder([{ column: c, direction: true }]);
    } else {
      old.direction = !old.direction;
      setOrder(oldOrder);
    }
  }

  const sectorCard = (row) => <div className='region' key={row.id}>
    <div className='title' onClick={() => handleClick(row.id)}>{row.name}</div>
    <div className='info'>
      <div>Drogi: {row.routes}</div>
      <Icon className='pointer'><img src={Pointer} alt="" onClick={() => handleMap(row.id)}></img></Icon>
    </div>
  </div>;

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }

  return (
    <div className='regionContainer'>
        <div className='view_title' >Sektor <ArrowDownwardOutlinedIcon onClick={() => handleOrderClick()}/></div>
      {sectors.map((row) => sectorCard(row))}
    </div>
  );
}

