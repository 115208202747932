import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import SearchForPartner from './SearchForPartner';
import style from './modalStyle';

export default function SearchForPartnerModal(props) {
  const { open, handleClose, addNewActivity, crag_id } = props;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <SearchForPartner  handleClose={handleClose} addNewActivity={addNewActivity} crag_id={crag_id}/>
        </Box>
      </Modal>
    </div>
  );
}
