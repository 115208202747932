import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import AddActivity from '../../view/gym/AddActivity';
import style from './modalStyle';

export default function BasicModal(props) {
  const { open, handleClose, addNewActivity } = props;

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AddActivity  handleClose={handleClose} addNewActivity={addNewActivity}/>
        </Box>
      </Modal>
    </div>
  );
}
