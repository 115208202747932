import { useEffect, useState } from 'react';

const Marker = (options) => {
  const [marker, setMarker] = useState();
  const svgMarker = {
    path: "M6.516,2.612L9.325,3.55l0.734,2.934l0.188,0.75l0.644,0.428l2.238,1.494L13.734,14H2.441l0.331-1.65l1.125-0.562 l0.875-0.438l0.191-0.959L6.516,2.612 M5,0L3,10l-2,1l-1,5h16l-1-8l-3-2l-1-4L5,0L5,0z",
    fillColor: "black",
    fillOpacity: 0.6,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
    anchor: new window.google.maps.Point(10, 15),
  };

  useEffect(() => {
    if (!marker) {
      setMarker(new window.google.maps.Marker({ icon: svgMarker }));
    }
    if(marker) {
      const infoWindow = new window.google.maps.InfoWindow({
        content: "",
        disableAutoPan: true,
      });

      marker.addListener("click", () => {
        infoWindow.setContent(options.label);
        infoWindow.open(options.map, marker);
      });
    }

    // remove marker from map on unmount
    return () => {
      if (marker) {
        marker.setMap(null);
      }
    };
  }, [marker]);

  useEffect(() => {
    if (marker) {
      marker.setOptions(options);
    }
  }, [marker, options]);
  return null;
};

export default Marker;
