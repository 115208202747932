import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from "react-hook-form";
import { Navigate, Link } from "react-router-dom";
import './Login.css';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { useSessionContext } from '../../context/sessionContext';

export default function Login() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { session, set_session } = useSessionContext();
  const [isError, setIsError] = useState(false);

  const fetchData = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/user/login`,
        data
      );
      setIsLoading(false);
      set_session(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(error.response.data.error || true);
      console.error(error);
    }
  }

  const onSubmit = data => {
    fetchData(data);
  };

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }

  return (
    <Box>
      {session && (
        <Navigate to="/search" replace={true} />
        )}
      <form onSubmit={handleSubmit(onSubmit)} style={{ flexDirection: 'column' }}>
        {/* register your input into the hook by invoking the "register" function */}
        <TextField id="email" label="Email" variant="standard" {...register("email", { required: true })} fullWidth/>
        {errors.email && <span style={{ color: 'red' }}>Email is required</span>}
        {/* include validation with required or other standard HTML validation rules */}
        <TextField type='password' id="password" label="Password" variant="standard" {...register("password", { required: true })} fullWidth/>
        {/* errors will return when field validation fails  */}
        {errors.password && <span style={{ color: 'red' }}>Password is required</span>}

        <input type="submit" />
        {isError && <Alert severity="error">{isError}</Alert>}
      </form>
      <Box sx={{ display: 'flex', justifyContent: 'space-around', mt: 2 }}>
        <Link to='/register' style={{ textDecoration: 'none' }}>Zarejstruj się</Link>
        <Link to='/reset_password' style={{ textDecoration: 'none' }}>Zresetuj hasło</Link>
      </Box>
    </Box>
  );
}
