import axios from 'axios';
import { useState } from 'react';
import { useSessionContext } from '../../context/sessionContext';
import IconButton from '@mui/material/IconButton';
import SendIcon from '@mui/icons-material/Send';
import CreateIcon from '@mui/icons-material/Create';
import TextField from '@mui/material/TextField';
import './write.css'

export default function WriteMessage(props) {
  const { userId } = props
  const [content, setContent] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [active, setActive] = useState(false);
  const { session } = useSessionContext();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/message`,
        { receiverId: userId, content },
        {
          headers: {
            Authorization: `B ${session.token}`
          }
        }
      );
      setIsLoading(false);
      setContent('');
      setActive(false);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  }

  function handleChange(event) {
    setContent(event.target.value);
  }

  function handleSend() {
    fetchData();
  }

  function handleActive() {
    setActive(true);
  }

  if(!active) {
    return  <div className='message'>
    <IconButton aria-label="send" color="primary" onClick={handleActive}>
      <CreateIcon />
    </IconButton>
  </div>
  }

  return  <div className='write_container'>
    <TextField
          id="standard-multiline-flexible"
          multiline
          value={content}
          onChange={handleChange}
          variant="standard"
          fullWidth
          autoFocus
        />
  <IconButton aria-label="send" disabled={!content || isLoading} color="primary" onClick={handleSend}>
    <SendIcon />
  </IconButton>
</div>
}
