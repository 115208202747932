import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { menuItems } from '../../navigation/sideMenu/menuItems';
import './home.css';


export default function Home() {
  const navigate = useNavigate();
  const handleClick = (route) => {
    navigate(route);
  }
  return (
    <div className='home_container'>
      <div className='home_info_container'>
        <div className='home_info_title'>
          <h2>Climb what you love</h2>
        </div>
        <div className='home_info_text'>
          <h4>Co chciałbyś dziś zrobić?</h4>
        </div>
      </div>
      <div className='card_container'>
        {menuItems.filter(item => item.name !== 'Home').map((item) => (
          <div className='card' onClick={() => handleClick(item.route)}>
            <div className='card_icon_container'>
              <img className='card_icon' src={`icons/${item.icon}.svg`} alt={item.icon}/>
            </div>
            <div className='card_name' >
              {item.name}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}