/* eslint-disable import/no-webpack-loader-syntax */
import React, { useEffect } from 'react';
import { useState } from 'react';
import './App.css';
import AppBar from './navigation/AppBar';
import AdapterDayJS from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Snackbar from '@mui/material/Snackbar';
import { FiltersProvider } from './context/filtersContext';
import { onMessageListener, supported } from './components/utils/firebase';
import { SessionProvider } from './context/sessionContext';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';

function App() {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');
  const [body, setBody] = useState('');
  const handleClose = () => {
    setShow(false);
    setMessage('');
    setBody('');
  }

  const onMessage = (message) => {
    setMessage(`${message.notification.title} od ${message.data.user_name}`);
    setBody(message.notification.body)
    setShow(true);
  }

  const initFirebase = async () => {
    const isSupported = await supported();
    if(isSupported) {
      onMessageListener(onMessage);
    }
  }

  useEffect(() => {
    initFirebase();
  }, []);

  return (
    <div className="App">
      <FiltersProvider>
        <SessionProvider>
          <LocalizationProvider dateAdapter={AdapterDayJS}>
            <AppBar/>
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              open={show}
              autoHideDuration={6000}
              onClose={handleClose}
            >
              <Alert onClose={handleClose} severity="info" variant='filled' sx={{ width: '100%' }}>
                <AlertTitle>{message}</AlertTitle>
                  {body}
              </Alert>
            </Snackbar>
          </LocalizationProvider>
        </SessionProvider>
      </FiltersProvider>
    </div>
  );
}

export default App;
