import axios from 'axios';
import { useState, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Wrapper } from "@googlemaps/react-wrapper";
import Marker from './Marker';
import GoogleMaps from './GoogleMaps';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const { REACT_APP_GOOGLE_MAPS_API_KEY } = process.env;

function getPath(searchParams) {
  if(!searchParams.get('id') || !searchParams.get('location')) {
    return 'region';
  }
  return `${searchParams.get('location')}/${searchParams.get('id')}`;
}

export default function MapContainer(props) {
  const [searchParams] = useSearchParams();
  const [clicks, setClicks] = useState([]);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [zoom, setZoom] = useState(3); // initial zoom
  const [center, setCenter] = useState({
    lat: 0,
    lng: 0,
  });

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const path = getPath(searchParams);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/${path}`
      );
      setIsLoading(false);
      Array.isArray(response.data) ?  setData(response.data) : setData([response.data]);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const onClick = (e) => {
    setClicks([...clicks, e.latLng]);
  };

  const onIdle = (m) => {
    setZoom(m.getZoom());
    setCenter(m.getCenter().toJSON());
  };

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Wrapper apiKey={REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMaps
          markerList={data.map((region) => ({ lat: region.lat, lng: region.long }))}
          zoom={12}
          onClick={onClick}
          onIdle={onIdle}
          style={{ flexGrow: "1", height: "100vh", width: "100vw" }}>
            {data.map((region) => <Marker position={{ lat: region.lat, lng: region.long }} label={region.name} key={region.id} />)}
        </GoogleMaps>
      </Wrapper>
    </div>
  );
}
