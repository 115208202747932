import * as React from 'react';
import { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import FiltersMenu from '../components/filters/Filters';
import ProfileMenu from './profile/ProfileMenu';
import SideMenu from './sideMenu/SideMenu';
import NavBar from './NavBar';
import AppRoutes from '../AppRoutes';
import { useSessionContext } from '../context/sessionContext';

export default function HideAppBar(props) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [profileAnchorEl, setProfileAnchorEl] = useState(null);
  const [sideAnchorEl, setSideAnchorEl] = useState(null);
  const isFilterMenuOpen = Boolean(anchorEl);
  const isProfileMenuOpen = Boolean(profileAnchorEl);
  const isSideMenuOpen = Boolean(sideAnchorEl);
  const { set_session } = useSessionContext();

  const getSession = () => {
    const sessionString = localStorage.getItem('session');
    const session = JSON.parse(sessionString);
    return session
  };

  const checkSession = () => {
    const session = getSession();
    set_session(session);
  }

  useEffect(() => {
    checkSession();
  }, []);

  const handleFilterMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setProfileAnchorEl(null);
    setSideAnchorEl(null);
  };

  const profileMenuId = 'primary-profile-menu';
  const sideMenuId = 'primary-side-menu';

  return (
    <React.Fragment>
      <NavBar
        setProfileAnchorEl={setProfileAnchorEl}
        setSideAnchorEl={setSideAnchorEl}
        profileMenuId={profileMenuId}
        handleFilterMenuOpen={handleFilterMenuOpen} />
      {<SideMenu
        sideAnchorEl={sideAnchorEl}
        isSideMenuOpen={isSideMenuOpen}
        handleMenuClose={handleMenuClose}
        sideMenuId={sideMenuId} />}
      {<FiltersMenu
        anchorEl={anchorEl}
        isFilterMenuOpen={isFilterMenuOpen}
        setAnchorEl={setAnchorEl}
        handleMenuClose={handleMenuClose} />}
      {<ProfileMenu
        profileAnchorEl={profileAnchorEl}
        isProfileMenuOpen={isProfileMenuOpen}
        handleMenuClose={handleMenuClose}
        profileMenuId={profileMenuId} />}
      <Container style={{ padding: 0, maxHeight: { sm: '100vw', xl: '70vw'} }}>
        <AppRoutes />
      </Container>
    </React.Fragment>
  );
}
