import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, useLocation } from "react-router-dom";
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { getOrderQuery, getFiltersQuery } from '../../components/utils/query/queryHelper';
import { useFiltersListContext } from '../../context/filtersContext';
import { useSessionContext } from '../../context/sessionContext';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import StringAvatar from '../../components/avatar/StringAvatar';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import dayjs from 'dayjs';
import './activity.css';
import WritePrivateMessageModal from '../../components/modal/PrivatMessageModal'

export default function Sectors() {

  const [isLoading, setIsLoading] = useState(false);
  const [routes, setRoutes] = useState([]);
  const [isError, setIsError] = useState(false);
  const [order, setOrder] = useState([]);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const { pathname } = useLocation();
  const context = pathname.split('/')[1];
  const { session } = useSessionContext();

  const { set_filters, filtersValues, clear_filters } = useFiltersListContext();

  const handleOpenModal = (userId) => {
    setUserId(userId)
    setOpen(true);
  };
  const handleCloseModal = () => setOpen(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity?${getFiltersQuery(filtersValues, context)}${getOrderQuery(order)}`
      );
      setIsLoading(false);
      setRoutes(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  }

  const fetchFilters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/activity/filters`
      );
      set_filters(response.data);
    } catch(err) {
      console.error(err);
    }
  }


  useEffect(() => {
    fetchData();
  }, [order]);

  useEffect(() => {
    if(filtersValues && context === filtersValues.context) {
      fetchData();
    }
  }, [filtersValues]);

  useEffect(() => {
    fetchFilters();
    return () => clear_filters();
  }, []);

  const handleClick = (id) => {
  }

  const handleOrderClick = () => {
    const c = 'date_from';
    const oldOrder = order.map((o) => ({...o}));
    const old = oldOrder.find(s => s.column === c);
    if(!old) {
      // oldOrder.push({ column: c, direction: true });
      setOrder([{ column: c, direction: true }]);
    } else {
      old.direction = !old.direction;
      setOrder(oldOrder);
    }
  }

  const HtmlTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0} arrow/>
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
      },
    }));

  const card = (activity) => <div className='route' onClick={() => handleClick(activity.id)}>
    <div className='route_info'>
      <HtmlTooltip
        title={
            <React.Fragment>
              <div className='activity_tooltip'>
                <Typography color="inherit">Send Message</Typography>
                {session.id !== activity.user_id &&  <IconButton aria-label="send" color="primary" onClick={() => handleOpenModal(activity.user_id)}>
                  <CreateIcon />
                </IconButton>}
              </div>
            </React.Fragment>
        }
      >
        <div>
          <StringAvatar userName={activity.user_name} small={true}/>
          <div className='box_text'>{activity.user_name}</div>
        </div>
      </HtmlTooltip>
      <div className='route_title' >{activity.type === 'gym' ? 'Sciana' : 'Skały'}</div>
      <div className='route_title' >{activity.target_name}</div>
    </div>
    <div className='route_info'>
      <div className='route_infoBox'>
        <div className='box_text'>{activity.content}</div>
      </div>
    </div>
    <div className='route_info'>
      <div className='activity_infoBox'>
        <div className='route_title' >Dzień</div>
        <div className='route_title'>{dayjs(activity.date_from).format('YYYY-MM-DD')}</div>
      </div>
      <div className='activity_infoBox'>
        <div className='route_title' >Godziny</div>
        <div className='box_text'>od {activity.hour_from} do {activity.hour_to}</div>
      </div>
    </div>
  </div>;

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }
  return (
    <div className='route_container'>
      <div className='view_title' onClick={() => handleOrderClick()}>Aktywności <ArrowDownwardOutlinedIcon /></div>
      {routes.map((route) => card(route))}
      <WritePrivateMessageModal open={open} handleClose={handleCloseModal} userId={userId}/>
    </div>
  );
}

