import axios from 'axios';
import * as React from 'react';
import { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TimePicker from '@mui/lab/TimePicker';
import DatePicker from '@mui/lab/DatePicker';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import CircularProgress from '@mui/material/CircularProgress';
import { useSessionContext } from '../../context/sessionContext';
import dayjs from 'dayjs';

export default function SearchForPartner(props) {
  const { handleClose, crag_id } = props;
  const { register, handleSubmit, formState: { errors }, control, reset } = useForm();
  const [isLoading, setIsLoading] = useState(false);
  const { session } = useSessionContext();
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();

  const { pathname } = useLocation();
  const pathSplitted = pathname.split('/');

  const fetchData = async (data) => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/activity/crag/${crag_id}`,
        data,
        {
          headers: {
            Authorization: `B ${session.token}`
          }
        }
      );
      setIsLoading(false);
      // addNewActivity(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(error.response.data.error || true);
      console.error(error);
    }
  }

  const prepareData = (data) => {
    return {
      content: data.content,
      hour_from: dayjs(data.hour_from).format('hh:mm:ss'),
      hour_to: dayjs(data.hour_to).format('hh:mm:ss'),
      date_from: dayjs(data.date_from).format('YYYY-MM-DD'),
      date_to: dayjs(data.date_to).format('YYYY-MM-DD'),
      type: data.type
    }
  }

  const onSubmit = async data => {
    const transformedData = prepareData(data);
    await fetchData(transformedData);
    reset();
    handleClose();
  };

  const handleGoToLogin = () => {
    handleClose();
    return navigate("/login");
  }
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column'
  };

  const roundTime = (time) => {
    const minutes = time.minute();
    const hours = time.hour();
    const quarters = Math.round(minutes / 15);
    const newMinutes = quarters * 15;
    const newTime = dayjs().hour(hours).minute(newMinutes).second(0);

    return newTime;
  }

  if(!session) {
    return (
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Aby dodać aktywnośc musisz się zalogować
        </Typography>
        <Button onClick={handleGoToLogin}>Przejdź do logowania</Button>
        <Button onClick={handleClose}>Zamknij</Button>
      </Box>
    )
  }

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)} style={{ flexDirection: 'column' }}>
        <Box>
          <Controller
                name='hour_from'
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <TimePicker
                    ampm={false}
                    label="Godzina od"
                    value={field.value}
                    onChange={(date) => field.onChange(roundTime(date))}
                    renderInput={(params) => <TextField style={{ width: '50%' }} {...params} />}
                  />
                )}
            />
          <Controller
                name='hour_to'
                control={control}
                render={({ field }) => (
                  <TimePicker
                    ampm={false}
                    label="Godzina do"
                    value={field.value}
                    onChange={(date) => field.onChange(roundTime(date))}
                    renderInput={(params) => <TextField style={{ width: '50%' }} {...params} />}
                  />
                )}
            />
        </Box>
        {errors.hour_from && <span style={{ color: 'red' }}>Hour from is required</span>}
        {errors.hour_to && <span style={{ color: 'red' }}>Hour_to is required</span>}
        <Box sx={{ mt: '10px' }}>
          <Controller
                name='date_from'
                control={control}
                render={({ field }) => (
                  <DatePicker
                    label="Dzień od"
                    value={field.value}
                    onChange={(date) => field.onChange(date)}
                    renderInput={(params) => <TextField style={{ width: '50%' }} {...params} />}
                  />
                )}
            />
        </Box>
        {errors.date_from && <span style={{ color: 'red' }}>Date_from is required</span>}
        <TextField id="content" label="Opis" variant="standard" {...register("content", { required: true })} fullWidth/>
        {errors.content && <span style={{ color: 'red' }}>Content is required</span>}
        <input type="submit" value='Dodaj aktywność' />
        {isError && <Alert severity="error">{isError}</Alert>}
      </form>
    </Box>
  )
}