import axios from 'axios';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, createSearchParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Icon } from '@mui/material';
import Pointer from '../../icons/pointer.svg';
import { getOrderQuery } from '../../components/utils/query/queryHelper';
import { useFiltersListContext } from '../../context/filtersContext';

export default function Crags() {

  const [isLoading, setIsLoading] = useState(false);
  const [crags, setCrags] = useState([]);
  const [isError, setIsError] = useState(false);
  const [order, setOrder] = useState([]);

  const { set_filters } = useFiltersListContext();

  const params = useParams();

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/crag?where=sector;${params.sectorId}${getOrderQuery(order)}`
      );
      setIsLoading(false);
      setCrags(response.data);
    } catch (error) {
      setIsLoading(false);
      setIsError(true);
      console.error(error);
    }
  }

  const fetchFilters = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/crag/filters`
      );
      set_filters(response.data);
    } catch(err) {
      console.error(err);
    }
  }

  const navigate = useNavigate();
  const handleClick = (route) => {
    navigate(`/route/${route}`);
  }

  useEffect(() => {
    fetchData();
  }, [order]);

  useEffect(() => {
    fetchFilters();
  }, []);

  const handleMap = (cragId) => {
    navigate({ pathname: '/map', search: createSearchParams({ location: 'crag', id: cragId }).toString() });
  }

  if(isLoading) {
    return (
      <Box sx={{ display: 'flex' }}>
      <CircularProgress />
    </Box>
    )
  }

  const card = (crag) => <div className='region' key={crag.id}>
      <div className='route_info' onClick={() => handleClick(crag.id)}>
        <div className='route_title' >{crag.name}</div>
      </div>
      <div className='route_info'>
        <div className='route_infoBox'>
          <div className='box_text'>Drogi: {crag.routes}</div>
        </div>
        <div className='route_infoBox'>
          <Icon className='route_pointer'>
            <img src={Pointer} alt="" onClick={() => handleMap(crag.id)}></img>
          </Icon>
        </div>
      </div>
    </div>;

  return (
    <div className='regionContainer'>
        <div className='view_title' >Skała</div>
      {crags.map((crag) => card(crag))}
    </div>
  );
}

