import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useFiltersListContext } from '../../context/filtersContext';
import { Grades } from '../utils/enums/grades';
import { GymTypes } from '../utils/enums/gymType';
import { ActivityTypes } from '../utils/enums/activity';
import CustomNativeSelect from '../inputs/nativeSelect';
import CustomDeepNativeSelect from '../inputs/nativeDeepSelect';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

export default function FiltersMenu (props) {
  const { anchorEl, isFilterMenuOpen, setAnchorEl, handleMenuClose } = props;
  const { filtersList, set_filters_values } = useFiltersListContext();
  const [filterValues, setFilterValues] = useState([]);
  const { pathname } = useLocation();

  useEffect(() => {
    setFilterValues([]);
  }, [pathname]);

  const handleFilterValueChange = (e) => {
    const oldFilters = filterValues.map((o) => ({...o}));
    const old = oldFilters.find(f => f.name === e.target.name);
    if(old) {
      old.value = e.target.value;
    } else {
      oldFilters.push({ name: e.target.name, value: e.target.value });
    }

    setFilterValues(oldFilters);
  }

  const handleCleanOneFilter = (name) => {
    const oldFilters = filterValues.filter((f) => f.name !== name);
    setFilterValues(oldFilters);
  }

  const handleClearFilters = () => {
    setFilterValues([]);
  }

  const handleApplyFilters = () => {
    setAnchorEl(null);
    const newContext = pathname.split('/')[1];
    set_filters_values(filterValues, newContext);
  };

  const renderElement = (f, i) => {
    switch (f.type) {
      case 'regions':
        return (<CustomDeepNativeSelect name={f.name} type='region' key={`${f.name}-${i}`} handleFilterValueChange={handleFilterValueChange} filterValues={filterValues} handleCleanOneFilter={handleCleanOneFilter}/>);
      case 'areas':
        return (<CustomDeepNativeSelect name={f.name} type='area' key={`${f.name}-${i}`} handleFilterValueChange={handleFilterValueChange} filterValues={filterValues} handleCleanOneFilter={handleCleanOneFilter}/>);
      case 'sectors':
        return (<CustomDeepNativeSelect name={f.name} type='sector' key={`${f.name}-${i}`} handleFilterValueChange={handleFilterValueChange} filterValues={filterValues} handleCleanOneFilter={handleCleanOneFilter}/>);
      case 'crags':
        return (<CustomDeepNativeSelect name={f.name} type='crag' key={`${f.name}-${i}`} handleFilterValueChange={handleFilterValueChange} filterValues={filterValues} handleCleanOneFilter={handleCleanOneFilter}/>);
      case 'voivodeship':
        return (<CustomDeepNativeSelect name={f.name} type='voivodeship' prefix='gym' key={`${f.name}-${i}`} handleFilterValueChange={handleFilterValueChange} filterValues={filterValues} handleCleanOneFilter={handleCleanOneFilter}/>);
      case 'town':
        return (<CustomDeepNativeSelect name={f.name} type='town' prefix='gym' key={`${f.name}-${i}`} handleFilterValueChange={handleFilterValueChange} filterValues={filterValues} handleCleanOneFilter={handleCleanOneFilter}/>);
      case 'grades':
        return (<CustomNativeSelect options={Grades} type={f.name} name={f.name} key={`${f.name}-${i}`} handleFilterValueChange={handleFilterValueChange} filterValues={filterValues} handleCleanOneFilter={handleCleanOneFilter}/>);
      case 'gym_type':
        return (<CustomNativeSelect options={GymTypes} name={f.name} key={`${f.name}-${i}`} handleFilterValueChange={handleFilterValueChange} handleCleanOneFilter={handleCleanOneFilter} />);
      case 'activity_type':
        return (<CustomNativeSelect options={ActivityTypes} name={f.name} key={`${f.name}-${i}`} handleFilterValueChange={handleFilterValueChange} handleCleanOneFilter={handleCleanOneFilter} />);
      case 'string':
        return (<TextField fullWidth name={f.name} id={`${f.name}-text-field`} key={`${f.name}-${i}`} label={f.name} variant="filled" onChange={handleFilterValueChange} />)
      default:
        return (<TextField fullWidth name={f.name} id={`${f.name}-default-text-field`} key={`${f.name}-${i}`} label={f.name} variant="filled" onChange={handleFilterValueChange} />)
    }
  }

  const filterMenuId = 'primary-filters-menu';
  return  (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={filterMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isFilterMenuOpen}
      onClose={handleMenuClose}
    >
      {filtersList && filtersList.map((f, i) => (
        renderElement(f, i)
      ))}
      <Button variant="outlined" onClick={handleApplyFilters}>Zastosuj</Button>
      <Button variant="outlined" onClick={handleClearFilters}>Wyczyść <DeleteForeverIcon /></Button>
    </Menu>
  );
}