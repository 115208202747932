import dayjs from 'dayjs';
import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useSessionContext } from '../../context/sessionContext';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CreateIcon from '@mui/icons-material/Create';
import WritePrivateMessageModal from '../../components/modal/PrivatMessageModal'

import StringAvatar from '../../components/avatar/StringAvatar';

export default function ActivityCard(props) {
  const { row, handleCommentsOpen, open, color } = props;

  const { session } = useSessionContext();
  const [openModal, setOpenModal] = useState(false);
  const [userId, setUserId] = useState(null);

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} enterTouchDelay={0} arrow/>
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const handleOpenModal = (userId) => {
    setUserId(userId)
    setOpenModal(true);
  };
  const handleCloseModal = () => setOpenModal(false);

  return (
    <Card sx={{ width: '95vw', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 1024, bgcolor: color }}>
      <Box style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <HtmlTooltip
            title={
                <React.Fragment>
                  <div className='activity_tooltip'>
                    <Typography color="inherit">Send Message</Typography>
                    {session.id !== row.user_id &&  <IconButton aria-label="send" color="primary" onClick={() => handleOpenModal(row.user_id)}>
                      <CreateIcon />
                    </IconButton>}
                  </div>
                </React.Fragment>
            }
          >
        <Box style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
          <StringAvatar userName={row.user_name} sx={{ m: 5 }}/>
          <Typography sx={{ ml: 1 }}>{row.user_name}</Typography>
        </Box>
        </HtmlTooltip>
        <Box sx={{ p: 1 }}>
          {`${row.hour_from} - ${row.hour_to}`}
        </Box>
      </Box>
      <Box sx={{ width: '95vw' }}>
        {row.content}
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', p: 1 }}>
        <Box>
          {row.date_to ? `${dayjs(row.date_from).format('YYYY-MM-DD')} - ${dayjs(row.date_to).format('YYYY-MM-DD')}` : dayjs(row.date_from).format('YYYY-MM-DD')}
        </Box>
        <Box>
          {dayjs(row.created_at).format('YYYY-MM-DD HH:mm')}
        </Box>
      </Box>
      <Box sx={{ width: '100%' }}>
        <ListItemButton onClick={handleCommentsOpen}>
          <ListItemText sx={{ textAlign: 'end' }} primary={`Komentarze ${row.comments?.length || 0}`} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </Box>
      <WritePrivateMessageModal open={openModal} handleClose={handleCloseModal} userId={userId}/>
    </Card>
  )
}